import React from "react";
import "./App.css";
import Header from "./components/Header";
import BrandArea from "./components/Partnered-Brand";
import Services from "./components/Services";
import AboutSection from "./components/About";
import FactsSection from "./components/Facts";
import Footer from "./components/Footer";

function App() {
  return (
    <React.Fragment>
      <Header />
      <AboutSection />
      <Services />
      <FactsSection />
      <BrandArea />
      <Footer />
    </React.Fragment>
  );
}

export default App;
