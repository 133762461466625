import React from "react";
import AiImage from "./Images/rash.ai.png";

const Header = () => {
  return (
    <header className="header-area">
      {/* Navbar */}
      <div className="navbar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a
                  className="navbar-brand"
                  href="index.html"
                  style={{ color: "white" }}
                >
                  {/* <img src="assets/images/logo/logo.svg" alt="Logo" /> */}
                  LOGO
                </a>
                <a
                    className="main-btn navbar-toggler navbar-btn"
                    // data-scroll-nav="0"
                    style={{padding: "0px 1rem"}}
                    href="https://app.rashfordai.com/register"
                    // rel="nofollow"
                  >
                {/* <button
                  className="navbar-toggler navbar-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                > */}
                  {/* <div className="navbar-btn d-none d-sm-inline-block"> */}
                 
                    Try for free
                {/* </div> */}
                {/* </button> */}
                  </a>

                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  {/* <ul id="nav" className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a className="page-scroll active" href="#home">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#features">
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#about">
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#facts">
                        Why
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="javascript:void(0)">Team</a>
                    </li>
                    <li className="nav-item">
                      <a href="javascript:void(0)">Blog</a>
                    </li>
                  </ul> */}
                </div>

                {/* Navbar collapse */}
                <div className="navbar-btn d-none d-sm-inline-block">
                  <a
                    className="main-btn"
                    // data-scroll-nav="0"
                    href="https://app.rashfordai.com/register"
                    // rel="nofollow"
                  >
                    Try for free
                  </a>
                </div>
              </nav>
              {/* Navbar */}
            </div>
          </div>
          {/* Row */}
        </div>
        {/* Container */}
      </div>
      {/* Navbar area */}

      {/* Header Hero */}
      <div
        id="home"
        className="header-hero bg_cover"
        style={{
          // backgroundImage: "url(assets/images/header/banner-bg.svg)",
          backgroundImage: "url(https://i.pinimg.com/564x/d1/a9/2f/d1a92f173d4bffffefe6aee50a3fd003.jpg)",
          backgroundColor: "#2f6ee6",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="header-hero-content text-center">
                <h3
                  className="header-sub-title wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.2s"
                >
                  Rash.ai
                </h3>
                <h2
                  className="header-title wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.5s"
                >
                 The revolutionary writing AI designed to transform your creative endeavors
                </h2>
                <p
                  className="text wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.8s"
                >
                  Harnessing the power of artificial intelligence, Rash Ai is set to revolutionize the way we write, making it easier and more efficient than ever before.
                </p>
                <a
                  href="https://app.rashfordai.com/login"
                  className="main-btn wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="1.1s"
                  style={{ marginBottom: "1rem" }}
                >
                  Get Started
                </a>
              </div>
              {/* Header hero content */}
            </div>
          </div>
     
        </div>
        {/* Container */}
        <div id="particles-1" className="particles"></div>
      </div>
      {/* Header Hero */}
    </header>
  );
};

export default Header;
