import React from 'react';

const AboutSection = () => {
  return (
    <section id="about">
      {/* ABOUT PART 1 */}
      <div className="about-area pt-70">
        <div className="container">
          <div className="row"> 
            <div className="col-lg-6">
              <div
                className="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div className="section-title">
                  <div className="line"></div>
                  <h3 className="title">
                    Quick & Easy <span>to get started</span>
                  </h3>
                </div>
                {/* section title */}
                <p className="text">
                Gone are the days of staring at a blank page, struggling to find the right words. With Rash Ai, you can bid farewell to writer's block and unleash your creativity like never before. Our advanced algorithms are trained on a vast collection of literature, allowing our AI to generate high-quality writing that mirrors the styles and tones of renowned authors.
                </p>
                <a href="https://1app-rash-ai.vercel.app/register" className="main-btn">
                  Try it Free
                </a>
              </div>
              {/* about content */}
            </div>
            <div className="col-lg-6">
              <div
                className="about-image text-center mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <img src="assets/images/about/about1.svg" alt="about" />
              </div>
              {/* about image */}
            </div>
          </div>
          {/* row */}
        </div>
        {/* container */}
        <div className="about-shape-1">
          <img src="assets/images/about/about-shape-1.svg" alt="shape" />
        </div>
      </div>
      {/* ABOUT PART 1 ENDS */}

      {/* ABOUT PART 2 */}
      <div className="about-area pt-70">
        <div className="about-shape-2">
          <img src="assets/images/about/about-shape-2.svg" alt="shape" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-last">
              <div
                className="about-content ms-lg-auto mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div className="section-title">
                  <div className="line"></div>
                  <h3 className="title">
                  Incorporate Rash.ai  <span> into your writing journey</span>
                  </h3>
                </div>
                {/* section title */}
                <p className="text">
                Integrate Rash.ai seamlessly into your writing expedition for a revolutionary experience. Elevate your content effortlessly by incorporating the power of Rash.ai into every step of your creative process. Whether you're drafting, editing, or refining, let Rash.ai be your trusted companion, adding finesse and precision to your writing journey. Unleash the potential of AI-driven writing and redefine the way you craft compelling narratives.
                </p>
                <a href="https://1app-rash-ai.vercel.app/register" className="main-btn">
                  Try it Free
                </a>
              </div>
              {/* about content */}
            </div>
            <div className="col-lg-6 order-lg-first">
              <div
                className="about-image text-center mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <img src="assets/images/about/about2.svg" alt="about" />
              </div>
              {/* about image */}
            </div>
          </div>
          {/* row */}
        </div>
        {/* container */}
      </div>
      {/* ABOUT PART 2 ENDS */}

      {/* ABOUT PART 3 */}
      {/* <div className="about-area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div className="section-title">
                  <div className="line"></div>
                  <h3 className="title">
                    <span>Crafted for</span> SaaS, App and Software Landing Page
                  </h3>
                </div>
                <p className="text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  seiam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing.
                </p>
                <a href="javascript:void(0)" className="main-btn">
                  Try it Free
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-image text-center mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <img src="assets/images/about/about3.svg" alt="about" />
              </div>
            </div>
          </div>
        </div>
        <div className="about-shape-1">
          <img src="assets/images/about/about-shape-1.svg" alt="shape" />
        </div>
      </div> */}
      {/* ABOUT PART 3 ENDS */}
    </section>
  );
};

export default AboutSection;
