import React from 'react';

const Services = () => {
  return (
    <section id="features" className="services-area pt-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="section-title text-center pb-40">
              <div className="line m-auto"></div>
              <h3 className="title">
              Rash.ai   
                <span> helps you write better, faster, and smarter
</span>
              </h3>
            </div>
            {/* AI-powered,  
                <span> paraphrasing tool will enhance your writing</span> */}
            {/* section title */}
          </div>
        </div>
        {/* row */}
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-7 col-sm-8">
            <div
              className="single-services text-center mt-30 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="services-icon">
                <img
                  className="shape"
                  src="assets/images/services/services-shape.svg"
                  alt="shape"
                />
                <img
                  className="shape-1"
                  src="assets/images/services/services-shape-1.svg"
                  alt="shape"
                />
                <i className="lni lni-baloon"> </i>
              </div>
              <div className="services-content mt-30">
                <h4 className="services-title">
                  <a href="javascript:void(0)">Better</a>
                </h4>
                <p className="text">
                Unleash the power of Rash.ai to elevate the quality of your writing. Craft polished content effortlessly, ensuring every word resonates with precision and clarity. Experience the transformative impact of precision paraphrasing for superior communication.
                </p>
            
              </div>
            </div>
            {/* single services */}
          </div>
          <div className="col-lg-4 col-md-7 col-sm-8">
            <div
              className="single-services text-center mt-30 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div className="services-icon">
                <img
                  className="shape"
                  src="assets/images/services/services-shape.svg"
                  alt="shape"
                />
                <img
                  className="shape-1"
                  src="assets/images/services/services-shape-2.svg"
                  alt="shape"
                />
                <i className="lni lni-cog"> </i>
              </div>
              <div className="services-content mt-30">
                <h4 className="services-title">
                  <a href="javascript:void(0)">Smarter</a>
                </h4>
                <p className="text">
                Elevate your writing intelligence with Rash.ai . This advanced tool goes beyond simple rephrasing, enhancing your text's coherence and depth. Embrace a smarter approach to content creation, where each word is optimized for impact and comprehension.
                </p>
              
              </div>
            </div>
            {/* single services */}
          </div>
          <div className="col-lg-4 col-md-7 col-sm-8">
            <div
              className="single-services text-center mt-30 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.8s"
            >
              <div className="services-icon">
                <img
                  className="shape"
                  src="assets/images/services/services-shape.svg"
                  alt="shape"
                />
                <img
                  className="shape-1"
                  src="assets/images/services/services-shape-3.svg"
                  alt="shape"
                />
                <i className="lni lni-bolt-alt"> </i>
              </div>
              <div className="services-content mt-30">
                <h4 className="services-title">
                  <a href="javascript:void(0)">Faster</a>
                </h4>
                <p className="text">
                Turbocharge your writing speed with Rash.ai . Say goodbye to time-consuming revisions and welcome the era of swift content creation. Accelerate your workflow, maintain quality, and meet deadlines effortlessly with the efficiency of Rash.ai.
                </p>
          
              </div>
            </div>
            {/* single services */}
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>
  );
};

export default Services;
