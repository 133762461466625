import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="footer-area pt-120">
      <div className="container">
        {/* <div
          className="subscribe-area wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="subscribe-content mt-45">
                <h2 className="subscribe-title">
                  Subscribe Our Newsletter <span>get regular updates</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="subscribe-form mt-50">
                <form action="#">
                  <input type="text" placeholder="Enter email" />
                  <button className="main-btn">Subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div> */}
        {/* subscribe area */}
        <div className="footer-widget pb-100">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="footer-about mt-50 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                {/* <a className="logo" href="javascript:void(0)">
                  <img src="assets/images/logo/logo.svg" alt="logo" />
                </a> */}
                <a
                  className="logo"
                  href="javascript:void(0)"
                  style={{ color: "white" }}
                >
                  {/* <img src="assets/images/logo/logo.svg" alt="Logo" /> */}
                  LOGO
                </a>
                <p className="text">
                  Jumpstart your writing journey with Rash.ai – the
                  epitome of quick and easy. Effortlessly transform your text,
                  saving time and ensuring a seamless experience from the very
                  first click. Say goodbye to complexity; say hello to
                  hassle-free writing with Rash.ai.
                </p>
                {/* <ul className="social">
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-facebook-filled"> </i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-twitter-filled"> </i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-instagram-filled"> </i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="lni lni-linkedin-original"> </i>
                    </a>
                  </li>
                </ul> */}
              </div>
              {/* footer about */}
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="footer-link d-flex mt-50 justify-content-sm-between">
                <div
                  className="link-wrapper wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <ul className="link">
                    <li>
                      <a href="javascript:void(0)">Paraphraser</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Grammer Checker</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Plagiarism Checker</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Punctuation Checker</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Essay Checker</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Spell Checker</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Word Counter</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Summary Generator</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Citation Generator</a>
                    </li>
                  </ul>
                </div>
                <div
                  className="link-wrapper wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                   <ul className="link">
                    <li>
                      <a href="javascript:void(0)">Story Writing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Creative Writing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Technical Writing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Copy Writing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Email Writing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Ebook Writing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Blog Writing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Academic Writing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Business Plan Writing</a>
                    </li>
                  </ul>
                </div>
                <div
                  className="link-wrapper wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <ul className="link">
                  <li>
                    <a href="javascript:void(0)">Research Writing</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Poetry Writing</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Article Writing</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">SEO Keyword Picker</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"> Resume Revamping</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Translator</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Proofreader</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Journals</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"> Social Media Caption</a>
                  </li>
                </ul>
                </div>
             
              </div>
            </div>
           
          </div>
        </div>
    
      </div>
      {/* container */}
      <div id="particles-2"></div>
    </footer>
  );
};

export default Footer;
