import React from "react";

const BrandArea = () => {
  return (
    <div className="brand-area pt-90 mb-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center pb-40">
              <div className="line m-auto"></div>
              <h3 className="title">Partners</h3>
            </div>

            <div className="brand-logo d-flex flex-wrap align-items-cen.ter justify-content-cen.ter justify-content-md-betwee.n" style={{margin: "auto", textAlign: "center", justifyContent: "space-between"}}>
              
              {/* single logo */}
              <a
                href="https://linktr.ee/rashfordeyo"
                target="_blank"
                className="col-md-4 col-lg-2 col-md-2 col-sm-3 mt-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <img
                  src="assets/img/rasheyo crypro.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </a>
              <a
                href="https://tatspacebot.disha.page/"
                target="_blank"
                className="col-md-4 col-lg-2 col-md-2 col-sm-3 mt-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <img
                  src="assets/img/tatspace.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </a>
              <a
                className="col-md-4 col-lg-2 col-md-2 col-sm-3 mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                href="https://www.facebook.com/akwaibomwedding"
                target="_blank"
              >
                <img
                  src="assets/img/akwaibomweddings-removebg-preview.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </a>

              {/* single logo */}
              <a
                href="https://kanny.ng/"
                target="_blank"
                className="col-md-4 col-lg-2 col-md-2 col-sm-3 mt-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.2s"
              >
                <img
                  src="assets/img/kanny-removebg-preview.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </a>

              {/* single logo */}
              <a
                href="https://www.facebook.com/blessing.ekans"
                target="_blank"
                className="col-md-4 col-lg-2 col-md-2 col-sm-3 mt-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.3s"
              >
                <img
                  src="assets/img/sb-removebg-preview.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </a>

              {/* single logo */}
              <a
                href="https://www.facebook.com/FredansTechnology"
                target="_blank"
                className="col-md-4 col-lg-2 col-md-2 col-sm-3 mt-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <img
                  src="assets/img/fredans2-removebg-preview.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </a>

              {/* single logo */}
              <div
                className="col-md-4 col-lg-2 col-md-2 col-sm-3 mt-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <img
                  src="assets/img/rbtech.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </div>

            </div>

            {/* <div className="brand-logo d-flex align-items-center justify-content-center justify-content-md-between">
              <div
                className="single-logo mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <img
                  src="assets/img/akwaibomweddings-removebg-preview.png"
                  style={{ height: "3rem", width: "auto" }}
                  alt="brand"
                />
              </div>
              <a href="https://kanny.ng/" target="_blank">
                <div
                  className="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="assets/img/kanny-removebg-preview.png"
                    alt="brand"
                    style={{ height: "3rem", width: "auto" }}
                  />
                </div>
              </a>

              <div
                className="single-logo mt-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.3s"
              >
                <img
                  src="assets/img/sb-removebg-preview.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </div>

              <a href="https://fredans.com/" target="_blank">
                <div
                  className="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="assets/img/fredans2-removebg-preview.png"
                    alt="brand"
                    style={{ height: "3rem", width: "auto" }}
                  />
                </div>
              </a>
              <div
                className="single-logo mt-30 wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <img
                  src="assets/img/rbtech.png"
                  alt="brand"
                  style={{ height: "3rem", width: "auto" }}
                />
              </div>
              <a href="https://rashfordeyo.com/" target="_blank">
                <div
                  className="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="assets/img/rasheyo crypro.png"
                    alt="brand"
                    style={{ height: "3rem", width: "auto" }}
                  />
                </div>
              </a>
            </div> */}
            {/* brand logo */}
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}
    </div>
  );
};

export default BrandArea;
